.event-list {
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
}

.event {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.event-time {
  display: flex;
  flex: 0.35;
  flex-direction: row-reverse;
  align-items: center;
  height: 2em;
  padding-right: 0.5em;
  color: rgb(189, 189, 189);
}

.event-timeline {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 2em;
}

.event-icon {
  width: 2em;
  height: 2em;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.event.phase-pending .event-icon {
  background-color: rgb(156, 39, 176);
}

.event.phase-current .event-icon {
  background-color: rgb(25, 118, 210);
}

.event.phase-done .event-icon {
  background-color: rgb(189, 189, 189);
  color: #ffffff;
}

.event-icon svg {
  width: 1.5em;
  height: 1.5em;
}

.event-divider {
  width: 0.1em;
  margin: 0.4em 0;
  flex: 1;
}

.event.phase-pending .event-divider {
  background-color: rgb(156, 39, 176);
}

.event.phase-current .event-divider {
  background-color: rgb(25, 118, 210);
}

.event.phase-done .event-divider {
  background-color: rgb(189, 189, 189);
}

.event-details {
  flex: 1;
}

.event-title {
  display: flex;
  align-items: center;
  height: 2em;
  padding-left: 0.5em;
}

.event.phase-done .event-title {
  color: rgb(189, 189, 189);
}

.event-image img {
  height: 6em;
  width: 10em;
  object-fit: contain;
}

.event.phase-done .event-image img {
  filter: grayscale(100%);
}
