@font-face {
  font-family: "Roboto-Light";
  src: local("Roboto-Light"), url(./fonts/Roboto-Light.ttf) format("truetype");
}

body {
  font-family: Roboto-Light;
  touch-action: pan-y;
  font-size: 16pt;
}

@media only screen and (min-width: 600px) {
  body {
    font-size: 24pt;
  }
}
